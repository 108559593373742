import { useCurrentUserInfo } from '../../contexts/UserContext';

declare const ChiliPiper;

const splitName = (name: string): [string?, string?] => {
  if (name === '') return [undefined, undefined];

  const words = name.split(' ');
  if (words.length < 2) return [name, undefined];

  const firstName = words.slice(0, -1).join(' ');
  const lastName = words[words.length - 1];
  return [firstName, lastName];
};

export const useBookCall = () => {
  const userCtxt = useCurrentUserInfo();
  const book = () => {
    const [firstName, lastName] = splitName(userCtxt?.fullName ?? '');
    //Chilly piper takes the information of the user from a item saved on localStorage called dataPardot.
    window.localStorage.setItem(
      'dataPardot',
      JSON.stringify({
        company: userCtxt?.companyName ?? '',
        email: userCtxt?.email ?? '',
        firstname: firstName ?? '',
        lastname: lastName ?? '',
        utm_campaign: '',
        utm_content: '',
        utm_medium: '',
        utm_source: '',
        utm_term: '',
      }),
    );
    ChiliPiper.showCalendar('unacast', 'inbound-router', {
      title: 'Thanks! What time works best for a quick call?',
    });
  };

  return book;
};
