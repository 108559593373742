import { UnaButton } from '@unacast-internal/unacast-ui/Button';
import { BookCallStyled } from './BookCallStyled';
import { useBookCall } from './helper';

export const BookCall = () => {
  const bookCall = useBookCall();
  return (
    <BookCallStyled>
      <UnaButton onClick={bookCall} size="large">
        Book a call
      </UnaButton>
    </BookCallStyled>
  );
};
